import React from 'react';
import Apply from '../apply-section/apply';
import Navbar from '../navbar/navbar2';

export default function ApplicationPage() {
    return (
        <>
        <Navbar />
        <Apply />
        </>
    )
}